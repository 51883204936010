











































































































































import Vue from 'vue'
import {
  grammarStore,
  snackbarStore,
} from '@/store'
import {
  Grammar,
} from '@/types/grammar'
import {
  Id,
} from '@/types/base'
import {
  DataTableHeader,
} from 'vuetify'

export default Vue.extend({
  data: () => ({
    headers: [
      {
        text: `ID`,
        value: `id`,
        groupable: true,
      },
      {
        text: `Text`,
        value: `text`,
        class: `text-no-wrap`,
      },
      {
        text: `Translation`,
        value: `translation`,
      },
      {
        text: `Description`,
        value: `description`,
        width: `30%`,
      },
      {
        text: `Rules`,
        value: `rules`,
      },
      {
        text: ``,
        value: `actions`,
        sortable: false,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    grammars (): (Grammar & Id)[] {
      return grammarStore.data
    },
  },
  async created () {
    try {
      await grammarStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load grammars`,
      })
    }
  },
  methods: {
    async deleteGrammar (grammar: (Grammar & Id)) {
      if (!confirm(`Are you sure you want to delete "${grammar.text}"?`)) return
      try {
        await grammarStore.delete(grammar.id)
      } catch (err) {
        snackbarStore.addSiteError({
          err, snackMessage: `Error deleting lesson`,
        })
      }
    },
  },
})
